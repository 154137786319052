// Libs
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import Cookies from "js-cookie";
import { MOONCAKE_DATA_UBICONNECT_FETCHED } from "@mooncake/mooncake-gateway-core/constants";
import { sendTrackingEvent } from "@mooncake/mooncake-gateway-core/utils";
// I18n
import i18n from "../../core/i18n";
// Actions
import { clearError, fetchEnd, fetchStart, setError } from "../../core/features/app/appSlice";
import { setUserInfo } from "../../features/user/userSlice";
import { createWallet } from "../../features/wallet/walletSlice";
// Components
import { Modal } from "@mooncake/ui";
import ModalContent from "../ModalContent";
import { getCountryName } from "@mooncake/utils";
import getGeoRedirectMapping from "../../utils/geoRedirectMapping";

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isConfirmed: false,
      countryName: "",
      storeName: "",
      isMultiCountry: false,
      activeElement: null
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.confirm = this.confirm.bind(this);
    this.startFetchingWallet = this.startFetchingWallet.bind(this);

    this.mapping = getGeoRedirectMapping(window.GeoRestrictedResources.GEORESTRICTED_CURRENTSITE);
  }

  componentDidMount() {
    document
      .getElementById("mooncake-data")
      .addEventListener(MOONCAKE_DATA_UBICONNECT_FETCHED, this.startFetchingWallet);
    const { country, locale } = this.props;

    document.addEventListener("openCurrencyConfirmationModal", this.openModal);

    if (Array.isArray(this.mapping)) {
      this.setState({
        storeName: i18n.t(`storeName.${window.GeoRestrictedResources.GEORESTRICTED_CURRENTSITE}`),
        isMultiCountry: true
      });
    } else {
      getCountryName(this.mapping, locale).then(storeName => {
        this.setState({
          storeName: Array.isArray(storeName) ? storeName[0] : storeName,
          isMultiCountry: false
        });
      });
    }
    getCountryName(country, locale).then(countryName => {
      this.setState({
        countryName: Array.isArray(countryName) ? countryName[0] : countryName
      });
      this.props.onReady();
    });
  }

  componentWillUnmount() {
    document
      .getElementById("mooncake-data")
      .removeEventListener(MOONCAKE_DATA_UBICONNECT_FETCHED, this.startFetchingWallet);
    document.removeEventListener("openCurrencyConfirmationModal", this.openModal);
  }

  openModal() {
    const activeElement = document.activeElement.shadowRoot
      ? document.activeElement.shadowRoot.activeElement
      : document.activeElement;
    this.props.clearError();

    this.setState(() => ({
      isOpen: true,
      activeElement
    }));

    sendTrackingEvent({
      eventType: "impressionevent",
      event: {
        location: "wallet activation",
        category: "display",
        locationPosition: "pop in",
        action: "visible"
      }
    });
  }

  closeModal() {
    if (this.state.activeElement) {
      this.state.activeElement.focus();
    }
    this.setState(() => ({
      isOpen: false,
      activeElement: null
    }));
    if (!this.state.isConfirmed) {
      sendTrackingEvent({
        eventType: "clickevent",
        event: {
          location: "wallet activation",
          category: "interaction",
          locationPosition: "pop in",
          action: "close pop in"
        }
      });
      this.props.onCloseModal();
    }
  }

  startFetchingWallet(info) {
    document
      .getElementById("mooncake-data")
      .removeEventListener(MOONCAKE_DATA_UBICONNECT_FETCHED, this.startFetchingWallet);
    this.props.setUserInfo(info.detail);
  }

  confirm() {
    const { country, createWallet, fetchStart, fetchEnd, onConfirm, setError, storeCurrency, userInfo } = this.props;

    const defaultCountry = Array.isArray(this.mapping) ? this.mapping[0] : this.mapping;
    const walletCountry = window.GeoRestrictedResources.GEORESTRICTED_REQUEST ? defaultCountry : country;

    fetchStart();
    createWallet(walletCountry, storeCurrency)
      .then(() => {
        if (userInfo && userInfo.userId) {
          Cookies.remove(`noWallet_${userInfo.userId}`);
        }
        this.setState(() => ({
          isConfirmed: true,
          isOpen: false
        }));

        sendTrackingEvent({
          eventType: "clickevent",
          event: {
            location: "wallet activation",
            category: "action",
            locationPosition: "pop in",
            action: "visible"
          }
        });

        onConfirm();
        fetchEnd();
      })
      .catch(() => {
        setError(i18n.t("walletCreationError"));
        fetchEnd();
      });
  }

  render() {
    const { initialized, loading, storeCurrency } = this.props;

    return (
      initialized && (
        <Modal
          isOpen={this.state.isOpen}
          contentMaxWidth="648px"
          onCloseModal={this.closeModal}
          disableClosing={loading}
          contentId="modal_wallet_currency_confirmation_content"
        >
          <ModalContent
            onConfirm={this.confirm}
            closeModal={this.closeModal}
            countryName={this.state.countryName}
            storeName={this.state.storeName}
            storeCurrency={storeCurrency}
            isMultiCountry={this.state.isMultiCountry}
          />
        </Modal>
      )
    );
  }
}

const mapStateToProps = state => ({
  initialized: state.app.initialized,
  loading: state.app.loading,
  locale: state.app.locale,
  userInfo: state.user.info
});

const mapDispatchToProps = {
  createWallet,
  clearError,
  fetchStart,
  fetchEnd,
  setError,
  setUserInfo
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTheme(Content)));
